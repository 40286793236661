import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Container, Spinner } from "react-bootstrap";
import DetalhesModal from "./DetalhesModal";
import apiUrl from "../../apiUrl";

function PainelSincornizacao(props) {
    const [dadosOrganizados, setDadosOrganizados] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [empresaSelecionada, setEmpresaSelecionada] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [loading, setLoading] = useState(true);

    let listaNaoSincronizaramEmail = []


    let htmlAviso = `
    <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>

<body style="margin: 0; padding: 20px 5px; color: black; ; background-color: #ffffff; font-family: Helvetica;">
    <table align="center" style="padding-bottom: 20px;">
        <tr>
            <td align="center">
                <img src="https://veloxdp.com.br/image/Logo%20Nova%20Preta%20Horizontal.png"
                    style="max-width: 400px; max-height: 400px;">
            </td>
        </tr>
    </table>

    <table bgcolor="white" style="border-radius: 15px; border: solid 1px #bec0c4; padding: 0px 20px; max-width: 800px;"
        align="center">
        <tr>
            <td align="center">
                <h1 style="text-align: center; display: inline-block; color: rgb(201, 5, 5);">
                    AVISO - Velox Não Está Sincronizando
                </h1>
                <hr style="background-color: #bec0c4; height: 1px ; border: none;">
            </td>
        </tr>
        <tr>
            <td style="padding: 0px 10px; font-size: 16px;">

                <p style="font-size: 20px; text-align: center">O executável do Velox no servidor não está
                    realizando a sincronização de forma adequada.
                </p>

                <p>
                    Por gentileza,verifiquem se o programa está em execução e, caso não esteja, realizem a
                    reinicialização do mesmo. Isso é necessário para garantir que a sincronização com o banco de dados
                    seja efetuada
                    corretamente, permitindo assim o envio dos avisos.
                </p>
                <p>Qualquer duvida entre em contato com o nosso suporte</p>
                <ul>
                    <li>Telefone / WhatsApp: +55 (47) 99989-1745 </li>
                    <li>Email: suporte.gt2a@gmail.com</li>
                </ul>
            </td>
        <tr>
            <td align="center" style="padding-top: 5px; font-size: 16px;">
                <p style="color: #888888;"><i>E-mail automático, não responda a esta mensagem.</i></p>
            </td>
        </tr>
        </tr>
    </table>

</body>

</html>
    `

    const token = localStorage.getItem('token');

    function organizarDados(dados) {
        const empresas = {};

        dados.forEach((row) => {
            const { empresa_id, data_sincronizacao, data_cad, nome_empresa, email } = row;

            if (!empresas[empresa_id]) {
                empresas[empresa_id] = {
                    empresa_id,
                    data_sincronizacao: [],
                    data_cad: [],
                    nome_empresa,
                    email
                };
            }

            empresas[empresa_id].data_sincronizacao.push(data_sincronizacao);
            empresas[empresa_id].data_cad.push(data_cad);
        });

        const resultado = Object.values(empresas);
        return resultado;
    }

    useEffect(() => {
        axios.post(`${apiUrl}empresa-log`, { token: token })
            .then(response => {
                setDadosOrganizados(organizarDados(response.data));
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, [])

    const handleModal = (empresa) => {
        setShowModal(!showModal);
        if (empresa) {
            setEmpresaSelecionada(empresa);
        }
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }

        setSelectedColumn(column);
    };

    const sortEmpresas = (a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    };

    let sortedEmpresas = dadosOrganizados;

    if (sortColumn) {
        sortedEmpresas = dadosOrganizados.sort(sortEmpresas);
    }

    const renderArrowIcon = (column) => {
        if (selectedColumn === column) {
            return sortOrder === "asc" ? <span>&darr;</span> : <span>&uarr;</span>;
        }
        return null;
    };

    async function enviarEmails() {
        // for (let i = 0; i < listaNaoSincronizaramEmail.length; i++) {
        for (let i = 0; i < 1; i++) {
            const email = listaNaoSincronizaramEmail[i].email;

            console.log('ativado')
            let templateParms = {
                destino: email,
                assunto: '❌ VeloxDP - Não Está Sincronizando'
            }

            await axios.post(`${apiUrl}aviso-sincronizacao`, templateParms);
            console.log(`Email enviado para ${email}.`);
        }
    }


    function naoSincronizando() {
        let arrayDeClientes = []
        sortedEmpresas.map((empresa, index) => (
            arrayDeClientes.push({ nome: empresa.nome_empresa, data: empresa.data_sincronizacao[0], email: empresa.email })
        ))

        const dataAtual = new Date();

        dataAtual.setHours(0, 0, 0, 0); // Define o horário para 00:00:00

        function removerHorario(dataString) {
            // Divide a data em partes: dia, mês, ano
            const partes = dataString.split(' ')[0].split('/');
            // Cria um objeto Date com as partes da data
            return new Date(partes[2], partes[1] - 1, partes[0]);
        }

        const clientesComDataDiferente = arrayDeClientes.filter(cliente => {
            const dataCliente = removerHorario(cliente.data);
            const dataLimite = new Date(dataAtual.getTime() - (3 * 24 * 60 * 60 * 1000)); // Subtrai 3 dias em milissegundos
            return dataCliente.getTime() < dataLimite.getTime();
        });

        listaNaoSincronizaramEmail = clientesComDataDiferente
        // const nomesClientesComDataDiferente = clientesComDataDiferente.map(cliente => cliente.nome);
        return clientesComDataDiferente
    }

    const listaNaoSincronizaram = naoSincronizando()

    return (
        <Container className="h-screen justify-center pb-5">
            <DetalhesModal showModal={showModal} handleModal={handleModal} empresa={empresaSelecionada} />

            <h1 className="text-center mt-3 text-xl font-bold">Sincronização dos Clientes</h1>

            {loading ? (
                <div className="flex justify-center mt-10">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <div>
                    <table className="table table-bordered table-striped w-10/12 m-auto mt-5 mb-5">
                        <thead>
                            <tr className="cursor-pointer">
                                <th
                                    scope="col"
                                    onClick={() => handleSort('nome_empresa')}
                                >
                                    Nome da Empresa {renderArrowIcon('nome_empresa')}
                                </th>
                                <th
                                    scope="col"
                                    onClick={() => handleSort('data_sincronizacao')}
                                >
                                    Data de Sincronização {renderArrowIcon('data_sincronizacao')}
                                </th>
                                <th
                                    scope="col"
                                    onClick={() => handleSort('data_cad')}
                                >
                                    Data de Acesso {renderArrowIcon('data_cad')}
                                </th>
                                <th className="text-center" scope="col">Detalhes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedEmpresas.map((empresa, index) => (
                                <tr key={index} className="hover:bg-gray-100">
                                    <td>{empresa.nome_empresa}</td>
                                    <td>{empresa.data_sincronizacao[0]}</td>
                                    <td>{empresa.data_cad[0]}</td>
                                    <td className="flex justify-center">
                                        <Button variant="primary" className="bg-blue-500" onClick={e => handleModal(empresa)}>
                                            Ver Mais
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <h1 className="text-center text-2xl mb-4">Mais de 3 dias sem sincronizar</h1>
                    <table className="table table-bordered table-striped w-10/12 m-auto mt-5 mb-5">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Data</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody >
                            {listaNaoSincronizaram.map((cliente, index) => (
                                <tr key={index}>
                                    <td>{cliente.nome}</td>
                                    <td>{cliente.data}</td>
                                    <td>{cliente.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <div className="pb-4">
                <button onClick={enviarEmails} className="bg-blue-500 px-4 py-2 rounded-2xl m-auto block text-gray-50 text-2xl">Enviar Emails</button>
            </div>

        </Container>

    );
}

export default PainelSincornizacao;
