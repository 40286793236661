import React, { useState } from "react";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { Link } from "react-router-dom";

function ManualAvisos(props) {

    return (
        <div className="m-0 p-4 bg-blue-950">

            <div className='bg-white navbar p-4 flex justify-between items-center text-xl text-black rounded-md'>
                <img src="Velox DP logo.png" className='w-2/4 lg:w-44' />
                <Link to="/login"
                    className='bg-blue-950 text-white px-7 py-1 rounded-md' onClick={console.log('')}>
                    Login
                </Link>
            </div>

            <div className="max-w-6xl p-5 mt-5 m-auto rounded-2xl bg-white">
                <h1 className=" text-center text-3xl font-bold">Manual Velox Avisos</h1>

                <h2 className="text-xl font-bold mb-2">Introdução</h2>

                <p className="mb-2"> O Velox Avisos tem a função de enviar avisos de férias, contrato de experiência e afastamento de forma automatica para o cliente de vocês </p>

                <p className="mb-2">A ferramenta vai ler direto no banco de dados do Questor todos os prazos dos clientes de vocês e vai enviar os avisos automaticamente por email ou por WhatsApp, sempre que um funcionário estiver com o contrato de experiência proximo de vencer, ou quando ele estiver se aproximando da data limite do pagamento das férias e quando algum funcionário tiver pego um afastamento longo e estiver proximo da data desse funcionário retornar </p>

                <p className="mb-3">Segue abaixo um exemplo dos e-mails:</p>

                <div class="">
                    <div class="">
                        <div class="flex justify-center items-center ">
                            <img src="Afastamento.png" alt="Imagem 1" class="img-fluid" />
                        </div>
                            <div class="flex justify-center items-center ">
                                <img src="ferias.png" alt="Imagem 2" class="img-fluid" />
                            </div>
                        <div class="flex justify-center items-center">
                            <img src="experiencia.png" alt="Imagem 2" class="img-fluid" />
                        </div>
                    </div>
                </div>

                <h2 className="text-xl font-bold mb-2 mt-3">1. Tela Inicial</h2>

                <p>Para acessar o site e começar a usar a ferramenta basta acessar esse link: <a href="https://veloxdp.com.br/new/" className="text-cyan-800">veloxdp.com.br/new/</a>   </p>

                <br />

                <p>Ao entrar selecione uma empresa para consultar os dados sobre os funcionários dessa empresa, lembrando que isso não afeta as configurações e o lançamento de novas empresas no site é apenas um filtro inicial de funcionário</p>

                <img src="modal inicial.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual" />

                <h2 className="text-xl font-bold mb-2 mt-2">2. Dashboard</h2>

                <p>Aqui você encontra informações sobre os avisos que foram enviados, tando de contrato de experiência, férias e afastamentos.Em <strong>Ver Detalhes</strong> será exibido para quais funcionários e empresas os avisos foram enviados.</p>

                <img src="dashboard.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual shadow-md" />

                <h2 className="text-xl font-bold mb-2 mt-2">3. Configuração</h2>

                <p>Na aba <strong>Geral</strong>, informe o e-mail interno da contabilidade, para que o responsável receba por esse e-mail as interações com os e-mails de avisos e um relatório semanal dos avisos enviados.Para o campo <strong>Sincronizar</strong> , recomendamos a opção: <strong>á cada</strong> e no horário <strong>01:00</strong>,(esse é o tempo que a ferramenta fará a atualização dos dados).</p>

                <img src="config geral.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual shadow-md" />

                <p>Na aba <strong>Avisos</strong> você consegue definir com quantos dias de antecedência o Velox deverá enviar os avisos. Lembrando que para o contrato de experiência o aviso é enviado todos os dias e para as férias é enviado toda segunda-feira uma vez por semana, aqui também é possível definir qual o texto que deverá aparecer no corpo do e-mail e também poderá <strong>colocar a logo da contabilidade.</strong></p>

                <img src="config avisos.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual shadow-md" />

                <h2 className="text-xl font-bold mb-2 mt-2">4. Cadastro Usuário</h2>

                <p>Nesse menu é possível gerenciar e criar novos usuários para utilizar o sistema. Esses usuários serão os colaboradores da contabilidade que terão acesso à ferramenta:</p>

                <img src="cadastro usuario.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual shadow-md" />

                <p>Para criar um novo usuário basta clicar na aba <strong>Incluir</strong> e preencher todo os dados:</p>

                <img src="cadastro usuario incluir.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual shadow-md" />

                <h2 className="text-xl font-bold mb-2 mt-2">5. Cadastro Cliente</h2>

                <p>Nesse menu é possível gerenciar e cadastrar os novos clientes. Sempre que cadastrar uma empresa nova no Questor, é necessário fazer o cadastro no Velox. Assim que concluir o cadastro no Velox, as demais informações (cadastro de funcionários) serão alimentadas no Site de forma automática.</p>

                <br />

                <p>Na aba <strong>Listar</strong> aparece uma lista com todas as empresas cadastrada no Velox, aqui é possível editar ou excluir.</p>

                <img src="cadastro cliente.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual shadow-md" />

                <p> Na aba de <strong>Incluir</strong> vocês precisaram preencher as informações das novas empresas que forem se tornando novos clientes, basta preencher os dados com base no que está no Questor, o campo (e-mail) é o e-mail do seu cliente que irá receber os avisos e o campo (E-mail responsável) é o da contabilidade que irá receber as interações dessa empresa em específico esse ultimo campo é opcional.</p>

                <img src="cadastro cliente incluir.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual shadow-md" />

                <h2 className="text-xl font-bold mb-2 mt-2">6. Funcionário</h2>

                <p> Aqui é uma aba que vai mostrar todos os funcionários da empresa selecionada na tela inicial, aqui vocês podem consultar essas informações e também editar um funcionário para definir se o Velox deve ou não enviar aviso para essa pessoa.</p>

                <img src="funcionarios.png" alt="" className="m-auto rounded-xl mt-3 mb-3 img-manual shadow-md" />

                <p>Qual quer dúvidas basta entrar em contato conosco nos nossos canais de suporte</p>
                <br />
                <p>WhatsApp/Telefone: <strong>+55 (47)99989-1745 </strong> </p>
                <p>E-mail: <strong> suporte.gt2a@gmail.com </strong> </p>


            </div>

        </div>
    )
}

export default ManualAvisos