import React, { useState } from 'react';

const CookieConsentPopup = () => {

    const showCookies = localStorage.getItem('cookies-aceitos') === 'sim' ? false : true

    const [showPopup, setShowPopup] = useState(showCookies);



    const handleAccept = () => {
        // Aqui você pode adicionar lógica para armazenar o consentimento do usuário
        // e, em seguida, definir o estado para ocultar o popup.
        setShowPopup(false);
        localStorage.setItem('cookies-aceitos', 'sim')
    };



    return (
        showPopup && (
            <div className="fixed bottom-4 right-4 p-4 text-white bg-white z-10 shadow-lg rounded-lg">
                <div className="max-w-md mx-auto p-4 rounded-lg text-gray-800">
                    <p className="mb-2">
                        Este site utiliza cookies para melhorar sua experiência. Ao continuar a usar este site, você concorda com o uso de cookies.
                    </p>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        onClick={handleAccept}
                    >
                        Aceitar
                    </button>
                </div>
            </div>
        )
    );
};

export default CookieConsentPopup;
