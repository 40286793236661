import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import ManualAvisos from './components/ManualAvisos';
import LoginPage from './components/PaginaLogin';
import AutenticacaoADM from './components/painel_adm/AutenticacaoADM';
import PoliticasHome from './components/politicas/PoliticasHome';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/manual-avisos" element={<ManualAvisos />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/painel-adm" element={<AutenticacaoADM />} />
        <Route path="/politicas" element={<PoliticasHome />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );

}

export default App;
