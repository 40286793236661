import axios from 'axios';
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import apiUrl from '../../apiUrl';

const LoginADM = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const token = localStorage.getItem('token') || ''

    const salvaEmail = (event) => {
        setEmail(event.target.value);
    };

    const salvaSenha = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${apiUrl}loginADM`, { usuario: email, senha: password })
            .then(response => {
                localStorage.setItem('token', response.data.token)
                props.setUser(true)
            })
            .catch(error => {
                alert(error.response.data)
            })

    };

    useState(() => {
        axios.post(`${apiUrl}loginADM`, { usuario: email, senha: password, token: token })
            .then(response => {
                props.setUser(true)
            })
            .catch(error => {
                console.log(error.response.data)
            })
    })

    return (
        <div className=' animated-bg'>
            <Container className="flex h-screen w-full items-center justify-center">
                <Row className="bg-white w-96 rounded-lg p-6 border-t-4 border-blue-500 shadow-md">
                    <Col md={{ span: 12 }}>
                        <Form onSubmit={handleSubmit}>

                            <h1 className='text-center mb-4 font-bold text-3xl'>Login ADM</h1>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={salvaEmail}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Control
                                    type="password"
                                    className="mt-3"
                                    placeholder="Senha"
                                    value={password}
                                    onChange={salvaSenha}
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="w-full mt-3 bg-blue-500">
                                Login
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LoginADM;
