import React from "react";

function Cookies(props) {
    return (
        <div>
            <h1 className="text-center text-3xl mb-4"> Política de cookies </h1>

            <p className="mb-2 font-semibold mb-2">I. O que são cookies?</p>

            <p className="mb-2">Cookies são pequenos arquivos que são baixados no seu computador para melhorar a sua experiência no site.   Existem milhões de tipos diferentes de cookies, os quais podem ser organizados em vários grupos em razão da função que eles executam. Temos por exemplo:</p>

            <p className="font-semibold mb-2 mt-2"> a) Cookies necessários/obrigatórios: </p>

            <p>São cookies absolutamente necessários para que o site funcione. Esses cookies não coletam nenhuma informação que possa ser relacionada a você. O armazenamento deles dura somente o tempo da duração da sessão em nosso site. Como não ocorre coleta de dados, nós podemos usar este cookies sem o seu consentimento;  </p>

            <p className="font-semibold mb-2 mt-2"> b) Cookies originais: </p>

            <p>São aqueles que não são totalmente necessários para o funcionamento do nosso site. Porém, eles causam uma grande melhora no funcionamento do site. Esses cookies permitem que as configurações escolhidas por você, como a seleção de idioma, por exemplo, sejam salvas e você não precise ficar alterando o idioma a cada novo acesso;</p>

            <p className="font-semibold mb-2 mt-2"> c) Cookies de performance: </p>

            <p>Estes cookies armazenam informações sobre o modo como você utiliza nosso site, como por exemplo, qual a página que você acessa com mais frequência, ou ainda, se todas as mensagens relacionadas à informações que você precisa, realmente aparecem para você. Não ocorre o armazenamento de nenhuma outra informação. O objetivo é aumentar a facilidade de uso. Como a informação é armazenada apenas em forma anônima, não existe qualquer risco para você;</p>

            <p className="font-semibold mb-2 mt-2"> d) Cookies de terceiros (analíticos): </p>

            <p>São cookies de outras empresas que podem guardar informações sobre você como usuário para identificá-lo quando você visitar nosso site, ou ainda, melhorar o desempenho do site e fazer análises para fins estatísticos. Estes cookies podem ser desabilitados por você, porém pode ocorrer a redução da experiência completa que nosso site proporciona;</p>

            <p className="font-semibold mb-2 mt-2"> e) Cookies de publicidade/marketing </p>

            <p>São utilizados ​​para apresentar a você conteúdos de publicidade relevantes e adaptados às suas preferências. Eles também são usados ​​para determinar com que frequência aparece um anúncio e a eficácia de uma campanha publicitária. Estes cookies podem ser desabilitados por você, porém pode ocorrer a redução da experiência completa que nosso site proporciona.</p>

            <p>Nos capítulos seguintes, descrevemos quais tipos de cookies usamos e quais informações coletamos no uso destes cookies.</p>

            <p>Também vamos explicar para você como usamos estas informações e por que algumas vezes precisamos armazenar esses cookies.</p>

            <p>É importante deixar claro que alguns cookies, como dito acima, fazem parte do funcionamento do site, então são obrigatórios, porém, você pode impedir que outros cookies sejam utilizados, no entanto, isso pode prejudicar certos elementos da funcionalidade do site.</p>

            <p className="font-semibold mb-2 mt-2">II.Como a Empresa X usa cookies?</p>

            <p>Utilizamos cookies por vários motivos. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados ​​para fornecer um serviço que você usa.</p>

            <p className="font-semibold mb-2 mt-2">1.Cookies que a Empresa X utiliza:</p>

            <p>a)Cookies relacionados a boletins por e-mail</p>

            <p>Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados ​​para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos/não inscritos;</p>

            <p>b)Cookies relacionados a pesquisas
                Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas;</p>

            <p>c)Cookies relacionados a formulários</p>

            <p>Quando você envia dados por meio de um formulário como os encontrados nas páginas de contato, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura;</p>

            <p>d)Cookies de preferências do site</p>

            <p>Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências;</p>

            <p className="font-semibold mb-2 mt-2">III.Desativar cookies</p>

            <p>Você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você não desative os cookies.</p>

            <p>recomendável que você não desative os cookies.
                Os navegadores também oferecem o modo de navegação anônima, que não armazena seu histórico de sites visitados ou os cookies no dispositivo depois que você fecha todas as janelas anônimas.</p>

            <p className="font-semibold mb-2 mt-2">IV.Mais informações</p>

            <p>Esperamos que você tenha esclarecido suas dúvidas e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados.</p>

            <p>Para mais informações relacionadas aos seus dados pessoais, consulte nosso Aviso de Privacidade ou entre em contato com nosso Encarregado de Proteção de Dados no nosso Canal de Comunicação com o Titular.</p>

        </div>
    )
}

export default Cookies