import React, { useState } from "react";
import Contato from "./Contato";
import Cookies from "./Cookies";
import ProtecaoDados from "./ProtecaoDados";
import { Link } from "react-router-dom";

export default function PoliticasHome(props) {
    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    const classTabAtivo = "bg-blue-950 text-white px-4 py-1 rounded-md"
    const classTabInativo = "mx-3 hover:bg-slate-200 px-4 py-1 rounded-md"

    return (
        <div className="m-0 p-4 bg-blue-950 min-h-screen">

            <div className="limitador-de-largura">

                <div className='bg-white navbar p-4 flex justify-between items-center text-xl text-black rounded-md'>
                    <Link to="/">
                        <img src="Velox DP logo.png" className='w-2/4 lg:w-44' />
                    </Link>

                    <div>
                        <button
                            className={activeTab === "tab2" ? classTabAtivo : classTabInativo}
                            onClick={() => handleTabSelect("tab2")}
                        >
                            Política de cookies
                        </button>
                        <button
                            className={activeTab === "tab3" ? classTabAtivo : classTabInativo}
                            onClick={() => handleTabSelect("tab3")}
                        >
                            Política de Proteção de Dados
                        </button>
                        <button
                            className={activeTab === "tab1" ? classTabAtivo : classTabInativo}
                            onClick={() => handleTabSelect("tab1")}
                        >
                            Canal De Comunicação
                        </button>
                    </div>
                </div>

                <div className="p-5 mt-5 m-auto rounded-2xl bg-white">
                    {activeTab === "tab1" && <Contato />}
                    {activeTab === "tab2" && <Cookies />}
                    {activeTab === "tab3" && <ProtecaoDados />}
                </div>
            </div>

        </div>
    );
}
