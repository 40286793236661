import React from "react";
import { Button, Modal } from "react-bootstrap";

function DetalhesModal(props) {

    const dadosEmpresa = props.empresa

    return (
        <Modal show={props.showModal} onHide={props.handleModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Detalhes {dadosEmpresa.nome_empresa}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered table-striped w-10/12 text-center m-auto">
                    <thead>
                        <tr>
                            <th scope="col">Horário Sincronização</th>
                            <th scope="col">Horário Acesso</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dadosEmpresa.data_sincronizacao?.map((data, index) => {
                            if (index < 30) {
                                return (
                                    <tr key={index}>
                                        <td>{data}</td>
                                        <td>{dadosEmpresa.data_cad[index]}</td>
                                    </tr>
                                )
                            } else return null
                        })}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="bg-gray-400 border-0 " onClick={props.handleModal}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DetalhesModal