import React, { useState } from "react";
import emailjs from '@emailjs/browser'

export default function Contato(props) {

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [mensagem, setmMensagem] = useState('')
    const [formBtn, setFormBtn] = useState(false)

    const [formValidacao, setFormValidacao] = useState('')
    const [formStatus, setFormStatus] = useState(false)

    function sendEmail(e) {
        e.preventDefault()

        if (nome === '' || email === '' || mensagem === '') {
            setFormValidacao('Preencha todos os dados')
            setFormStatus(false)
            return
        } else {

            const templateParms = {
                to_name: nome,
                message: mensagem,
                email: email
            }

            setFormBtn(true)

            emailjs.send('service_lew7p7t', 'template_mjlvygn', templateParms, 'pAdp6-Hij4sGnAEml')
                .then((res) => {
                    setFormValidacao('Enviado com sucesso')
                    setFormStatus(true)
                    setEmail('')
                    setNome('')
                    setmMensagem('')
                    setFormBtn(false)
                }).catch((err) => {
                    console.log(err)
                    setFormValidacao('Ocorreu um erro tente novamente mais tarde')
                    setFormStatus(false)
                })
        }

    }

    return (
        <div>

            <h1 className="text-center text-3xl mb-5">Canal de Comunicação</h1>

            <div className="flex">
                <div>
                    <p>A GT2A Sistemas criou este espaço para que você, Titular de Dados Pessoais, possa exercer os direitos previstos no artigo 18 da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados – LGPD).</p>
                </div>
                <div>
                    <p>Este canal de comunicação é parte integrante de todo um sistema implementado na GT2A Sistemas, por intermédio da GT2A Sistemas, o nosso núcleo de direito Digital, proteção de dados e inovação no setor jurídico, para garantir a segurança das informações coletadas pela GT2A Sistemas.</p>
                </div>
            </div>

            <p className="mt-5">Caso você precise de mais informações, consulte nossa Política de Proteção de Dados e nossa Política de Cookies. Nestes documentos você encontrará diversas informações, como por exemplo: (i) quem é nosso Encarregado de Proteção de Dados (DPO) e quem são nossos Operadores; (ii) como, quando e porquê coletamos Dados Pessoais; (iii) como você deve proceder para nos acionar e quais os prazos de resposta; e (v) quais são nossas premissas quando tratamos os seus Dados Pessoais.</p>

            <h2 className="mt-5 text-2xl text-center text-blue-900">Fique à vontade para nos questionar por meio do formulário abaixo</h2>

            <form action="" onSubmit={sendEmail} className="cont-formulario bg-white m-auto mt-5 py-4 border-2 rounded-2xl text-center">

                <p className="mb-3 text-xl text-slate-500">Formulário de contato</p>

                <input onChange={e => setNome(e.target.value)} value={nome} class="appearance-none border-gray-300 border-2 rounded w-10/12 lg:w-8/12 mb-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-400 transition" id="username" type="text" placeholder="Seu nome" />

                <input onChange={e => setEmail(e.target.value)} value={email} class="appearance-none border-gray-300 border-2 rounded w-10/12 lg:w-8/12 mb-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-400 transition" id="username" type="email" placeholder="Seu email" />

                <textarea onChange={e => setmMensagem(e.target.value)} value={mensagem} id="message" rows="4" class="p-2.5 border-2 w-10/12 lg:w-8/12 focus:outline-none focus:shadow-outline text-gray-900 bg-white rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-400 transition" placeholder="Escreva uma mensagem"></textarea>

                <br />
                <p className={formStatus ? 'text-green-500 mt-2' : 'text-red-500 mt-2'}>{formValidacao}</p>

                <button disabled={formBtn} className='bg-blue-950 text-white w-10/12 lg:w-8/12 mb-3 p-2 mt-3 rounded-md' >SOLICITAR CONTATO</button>

            </form>

            <p className="mt-5 text-center">Os dados acima são coletados com base nos termos do artigo 7º, inciso II, da LGPD, diante da finalidade de cumprimento da obrigação legal de responder os Titulares de Dados, conforme previsão do artigo 18 e seguintes também da LGPD.
            </p>

        </div>
    )
}
