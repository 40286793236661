import React, { useEffect, useState } from 'react';
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser'
import CookieConsentPopup from './politicas/CookieConsentPopup';

function Home(props) {

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [mensagem, setmMensagem] = useState('')
    const [formBtn, setFormBtn] = useState(false)

    const [formValidacao, setFormValidacao] = useState('')
    const [formStatus, setFormStatus] = useState(false)

    const countUsuarios = useMotionValue(0);
    const usuariosAtivos = useTransform(countUsuarios, Math.round);

    const countColaboradores = useMotionValue(0);
    const colaboradores = useTransform(countColaboradores, Math.round)

    const countAvisos = useMotionValue(0);
    const avisos = useTransform(countAvisos, Math.round)

    function sendEmail(e) {
        e.preventDefault()

        if (nome === '' || email === '' || mensagem === '') {
            setFormValidacao('Preencha todos os dados')
            setFormStatus(false)
            return
        } else {

            const templateParms = {
                to_name: nome,
                message: mensagem,
                email: email
            }

            setFormBtn(true)

            emailjs.send('service_lew7p7t', 'template_mjlvygn', templateParms, 'pAdp6-Hij4sGnAEml')
                .then((res) => {
                    setFormValidacao('Enviado com sucesso')
                    setFormStatus(true)
                    setEmail('')
                    setNome('')
                    setmMensagem('')
                    setFormBtn(false)
                }).catch((err) => {
                    console.log(err)
                    setFormValidacao('Ocorreu um erro tente novamente mais tarde')
                    setFormStatus(false)
                })
        }

    }

    useEffect(() => {
        const animationUsuarios = animate(countUsuarios, 3500, { duration: 5 });
        const animationColaboradores = animate(countColaboradores, 45000, { duration: 5 });
        const animationAvisos = animate(countAvisos, 1200, { duration: 5 });


        return (animationUsuarios.stop, animationColaboradores.stop, animationAvisos.stop)
    }, []);

    return (
        <div className="m-0 p-0">

            <CookieConsentPopup />

            <section id='hero' className='animated-bg p-4 relative'>

                <div className='limitador-de-largura'>

                    <div className='bg-white navbar p-4 flex justify-between items-center text-xl text-black rounded-md'>
                        <img src="Velox DP logo.png" className='w-2/4 lg:w-44' />
                        <Link to="/login"
                            className='bg-blue-950 text-white px-7 py-1 rounded-md' onClick={console.log('')}>
                            Login
                        </Link>
                    </div>

                    <div className='text-center text-2xl font-bold mt-5 p-3 text-white lg:flex lg:items-center lg:justify-center'>

                        <motion.div initial={{ x: -300 }} animate={{ x: 0 }}
                            transition={{ ease: "easeOut", duration: 1.5 }}
                            className='lg:w-5/12 lg:h-80 lg:flex lg:flex-col lg:justify-center bg-grad lg:rounded-md lg:text-4xl lg:text-left lg:p-6 lg:z-10'>
                            <h1 className='font-normal text-4xl'>Software de gestão para departamento pessoal</h1>
                            <p className='mt-4 font-extralight text-xl lg:text-lg'>Simplifique e otimize a gestão do seu Departamento Pessoal com o Velox DP. Uma solução completa e intuitiva que automatiza processos, agiliza tarefas e garante conformidade com a legislação trabalhista. Tenha o controle total sobre admissões, rescisões, banco de horas, afastamentos e muito mais.</p>
                        </motion.div>
                        <motion.div initial={{ x: 300 }} animate={{ x: 0 }} transition={{ ease: "easeOut", duration: 1.5 }} >
                            <img className='mt-4 rounded-xl' id='hero-image' src="./Hero Image.png" alt="" srcset="" />
                        </motion.div>
                    </div>

                    <div id='cont-header-cards' className='bg-white shadow-2xl text-xl p-4 rounded-md absolute lg:flex lg:justify-around lg:p-4'>
                        <div className='text-center mb-3 lg:mb-0 margin-0'>
                            <span className='text-blue-800' >+</span>
                            <motion.p className='text-blue-800 inline'>{usuariosAtivos}</motion.p>
                            <p>Usuários Ativos</p>
                        </div>
                        <div className='text-center mb-3 lg:mb-0 margin-0'>
                            <span className='text-blue-800' >+</span>
                            <motion.p className='text-blue-800 inline'>{colaboradores}</motion.p>
                            <p>Colaboradores Gerenciados</p>
                        </div>
                        <div className='text-center'>
                            <span className='text-blue-800' >+</span>
                            <motion.p className='text-blue-800 inline'>{avisos}</motion.p>
                            <p>Avisos Enviado Por Dia</p>
                        </div>
                    </div>

                </div>

            </section >

            {/* SESSÃO 2 ------------------------------ */}


            <section className='min-h-screen bg-gradient-to-b pb-5 mb-3 from-gray-200 to-white lg:pt-28 pt-64  sessao-2 rounded-b-3xl'>

                <div className='limitador-de-largura'>

                    <div className='text-center bg-white rounded-lg border-b-4 border-blue-900 w-11/12 lg:w-10/12 m-auto px-3 box-shadown' >
                        <h1 className='text-3xl pt-5 pb-5'>Sobre o Velox DP</h1>
                        <p className='text-2xl font-light pb-5'>O Velox DP é uma ferramenta de gestão de Departamento Pessoal, projetada para auxiliar as empresas. Desenvolvido com foco na simplicidade e eficiência, nosso software oferece recursos abrangentes para facilitar o dia a dia dos profissionais de RH. Libere tempo, reduza erros e ganhe tranquilidade com o Velox DP.</p>
                    </div>

                    <div className='pt-14 text-3xl text-center' >
                        <h2>Recursos principais</h2>
                        <p className='text-2xl font-light p-5 lg:w-8/12 lg:m-auto'>Com o Velox DP, você tem acesso a uma variedade de recursos essenciais para a gestão do seu Departamento Pessoal</p>


                        <div className='cont-cards flex justify-evenly flex-wrap lg:justify-between '>

                            <div className='w-5/12 fadeInRight cards-width h-72 lg:h-56 border-b-4 bg-white rounded-lg box-shadown border-blue-900 flex flex-col justify-center items-center p-2 lg:m-4'>
                                <i className="bi bi-person-check mb-2 text-5xl"></i>
                                <div className="">
                                    <p className="text-lg lg:text-xl ">Cadastro de admissões e rescisões simplificado</p>
                                </div>
                            </div>

                            <div className='w-5/12 cards-width fadeInRight h-72 lg:h-56 border-b-4 mb-3 bg-white rounded-lg box-shadown border-blue-900 flex flex-col justify-center p-2 lg:m-4'>
                                <i className="bi bi-clock-history mb-2 mt-3 text-4xl"></i>
                                <div className="">
                                    <p className="text-lg lg:text-xl">Controle automatizado de banco de horas</p>
                                </div>
                            </div>

                            <div className='w-5/12 fadeInRight cards-width h-72 lg:h-56 border-b-4 bg-white rounded-lg box-shadown border-blue-900 flex flex-col justify-center p-2 lg:m-4'>
                                <i className="bi bi-calendar3 mb-2 mt-3 text-4xl"></i>
                                <div className="">
                                    <p className="text-lg lg:text-xl ">Gerenciamento de despesas médicas e benefícios</p>
                                </div>
                            </div>

                            <div className='w-5/12 fadeInRight cards-width h-72 lg:h-56 border-b-4 bg-white rounded-lg box-shadown border-blue-900 flex flex-col justify-center p-2 mb-3 lg:m-4'>
                                <i className="bi bi-list-check mb-2 mt-3 text-4xl"></i>
                                <div className="">
                                    <p className="text-lg lg:text-xl ">Acompanhamento de afastamentos e licenças</p>
                                </div>
                            </div>

                            <div className='w-5/12 fadeInRight cards-width h-72 lg:h-56 border-b-4 bg-white rounded-lg box-shadown border-blue-900 flex flex-col justify-center p-2 lg:m-4'>
                                <i className="bi bi-person-up mb-2 mt-3 text-5xl"></i>
                                <div className="">
                                    <p className="text-lg lg:text-xl ">Lançamento de eventos variáveis para personalizar as informações de cada funcionário</p>
                                </div>
                            </div>

                            <div className='w-5/12 fadeInRight cards-width h-72 lg:h-56 border-b-4 bg-white rounded-lg box-shadown border-blue-900 flex flex-col justify-center p-2 lg:m-4'>
                                <i className="bi bi-check-all mt-3 mb-2 text-4xl"></i>
                                <div className="">
                                    <p className="text-lg lg:text-xl ">Fácil de usar e de aprender, focado na experiência do usuário</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            {/* SESSÃO 3 ----------------------------------------- */}

            <section className='sessao-3 flex justify-center items-center text-center text-white'>

                <div className='sessao-3-texto'>
                    <h1 className='text-3xl mb-5'>Ao escolher o Velox DP, você desfruta de uma série de benefícios</h1>
                    <p className='text-2xl'>Economize tempo e reduza a burocracia do seu Departamento Pessoal;
                        Minimize erros e garanta a conformidade com a legislação trabalhista;
                        Acesse informações atualizadas e relatórios em tempo real;
                        Otimize processos e melhore a eficiência do seu RH;
                        Tenha tranqulidade com um software seguro e confiável.
                    </p>
                </div>

            </section>

            {/* SESSÃO 4 -------------------------------------- */}

            <section className='bg-grad-sec3 rounded-3xl py-20 px-2' >

                <div className='limitador-de-largura lg:flex lg:justify-center lg:items-center lg:flex-row-reverse'>

                    <div className='text-center text-white lg:w-3/6 '>

                        <h2 className='text-3xl mb-5'>Descubra como o Velox DP pode impulsionar a produtividade e o sucesso da sua empresa</h2>

                        <p className='text-2xl lg:text-2xl mb-4 font-thin'>
                            Além de todas as funcionalidades poderosas, o Velox DP se destaca pela sua integração perfeita com o software da Contabilidade que cuida da sua empresa. Simplifique ainda mais a gestão do seu Departamento Pessoal, economize tempo e otimize seus processos, conectando as informações inseridas no Velox DP  com a sua contabilidade. Uma combinação perfeita para impulsionar a eficiência do seu negócio!
                        </p>

                    </div>

                    <img src="./session 3.png" className=' m-auto lg:w-3/6 lg:p-10 w-11/12' alt="" srcset="" />
                </div>
            </section>

            {/* SESSÃO 5 ------------------------------------------------ */}

            <section className='mt-5'>
                <div className='limitador-de-largura text-center lg:w-8/12'>
                    <h1 className='text-4xl mb-5 font-medium'> Entre em contato </h1>

                    <p className='text-2xl px-5' >
                        Está pronto para transformar a gestão do seu Departamento Pessoal? Entre em contato conosco hoje mesmo e descubra como o Velox DP pode atender às suas necessidades. Nossa equipe está pronta para ajudar e fornecer uma demonstração personalizada do software.
                    </p>

                    <p className='mt-3 text-2xl italic' >“Simplifique seu trabalho e melhore a eficiência com o Velox DP”</p>

                    <div className='cont-formulario bg-white m-auto mt-5 border-2 rounded-2xl shadow-2xl'>
                        <p className='text-xl mt-3 mb-3'>FORMULÁRIO DE CONTATO</p>

                        <form action="" onSubmit={sendEmail} className='mb-3'>

                            <input onChange={e => setNome(e.target.value)} value={nome} class="appearance-none border-gray-300 border-2 rounded w-10/12 lg:w-8/12 mb-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-400 transition" id="username" type="text" placeholder="Seu nome" />

                            <input onChange={e => setEmail(e.target.value)} value={email} class="appearance-none border-gray-300 border-2 rounded w-10/12 lg:w-8/12 mb-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-400 transition" id="username" type="email" placeholder="Seu email" />

                            <textarea onChange={e => setmMensagem(e.target.value)} value={mensagem} id="message" rows="4" class="p-2.5 border-2 w-10/12 lg:w-8/12 focus:outline-none focus:shadow-outline text-gray-900 bg-white rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-400 transition" placeholder="Escreva uma mensagem"></textarea>

                            <br />
                            <p className={formStatus ? 'text-green-500 mt-2' : 'text-red-500 mt-2'}>{formValidacao}</p>

                            <button disabled={formBtn} className='bg-blue-950 text-white w-10/12 lg:w-8/12 mb-3 p-2 mt-3 rounded-md' >SOLICITAR CONTATO</button>

                        </form>

                    </div>

                </div>
            </section>

            {/* SESSÃO 6 ---------------------------------------- */}

            <section className='mt-5 bg-gray-300 rounded-t-3xl py-5'>

                <div className='lg:flex lg:justify-evenly lg:items-center'>

                    <div className='text-center lg:w-2/6'>
                        <h3 className='text-3xl lg:mb-3'>
                            Redes Sociais
                        </h3>
                        <div>
                            <a href="https://www.instagram.com/veloxdp/" rel="noreferrer" target="_blank">
                                <i className='bi bi-instagram text-2xl mr-3'></i>
                            </a>
                            <a href="https://www.facebook.com/veloxdp" rel="noreferrer" target="_blank">
                                <i class="bi bi-facebook text-2xl mr-3"></i>
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=554799891744" rel="noreferrer" target="_blank">
                                <i class="bi bi-whatsapp text-2xl"></i>
                            </a>
                        </div>
                    </div>

                    <div className='text-center lg:w-2/6'>
                        <p className='px-5 mt-5 mb-3 text-xl no-margin'>
                            Copyright © 2023 - Todos os direitos reservados GT2A Sistemas
                        </p>
                        <Link to="/politicas" className='px-5 text-xl underline'>LGPT - Lei Geral de Proteção de Dados</Link>
                    </div>

                </div>

            </section>

        </div>
    );
}

export default Home