import React from "react";

function ProtecaoDados(props) {
    return (
        <div>
            <h1 className="text-center text-3xl mb-4"> POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS </h1>

            <p className="mb-2">I. INTRODUÇÃO</p>

            <p className="mb-2">Com esta Política de Privacidade e Proteção de Dados a GT2A Sistemas Ltda
                Sistemas almeja demonstrar a seus clientes, parceiros e comunidade todas as
                medidas que visam garantir a privacidade, a proteção de dados pessoais e a
                segurança da informação dos dados pessoais que realiza tratamento na forma da
                Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018 - LGPD).</p>

            <p className="mb-2">II. CONCEITOS E SIGLAS</p>

            <p className="mb-2">A Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018 - LGPD) trouxe
                consigo diversos conceitos novos ou relacionados à questões técnicas de
                conformidade (compliance) e de setores como o de segurança da informação. Neste
                caminho, elaboramos esta lista a seguir, com definições que lhe ajudarão a
                compreender melhor este documento.</p>

            <p className="mb-2"><span className="font-semibold">LGPD: </span> Lei Geral de Proteção de Dados (Lei 13.709/2018);</p>

            <p className="mb-2"><span className="font-semibold">ANPD: </span> Agência Nacional de Proteção de Dados;</p>

            <p className="mb-2"><span className="font-semibold">Cliente/Titular/Consumidor: </span> Pessoa natural a quem se referem os dados pessoais
                que são objeto de tratamento;</p>

            <p className="mb-2"><span className="font-semibold">Cliente GT2A Sistemas Ltda: </span> Pessoa física ou jurídica que contrata os serviços da
                GT2A Sistemas Ltda Sistemas;</p>

            <p className="mb-2"><span className="font-semibold">Controlador: </span> Pessoa natural ou jurídica, de direito público ou privado, a quem
                competem as decisões referentes ao tratamento de dados pessoais;</p>

            <p className="mb-2"><span className="font-semibold">Operador: </span> Pessoa natural jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador;</p>

            <p className="mb-2"><span className="font-semibold">Encarregado: </span> Pessoa indicada pelo controlador para atuar como canal de
                comunicação entre o controlador, os Titulares dos dados e a Autoridade Nacional de
                Proteção de Dados (ANPD);</p>

            <p className="mb-2"><span className="font-semibold">Dado pessoal: </span>  Informação que possa ser vinculada à pessoa natural identificada ou
                identificável (art. 5º, inciso I, da LGPD). Exemplos: nome, estado civil, profissão, data
                de nascimento, documentos pessoais (CPF, RG, CNH, Carteira de Trabalho,
                passaporte, título de eleitor, etc.), endereço residencial ou comercial, telefone,
                e-mail, cookies e endereço IP;</p>

            <p className="mb-2"><span className="font-semibold">Dado pessoal sensível: </span>  Dado pessoal (informação) que se encaixe nas definições
                do inciso II do artigo 5º da LGPD. Dado pessoal com maior potencial danoso em
                caso de incidente; </p>

            <p className="mb-2"><span className="font-semibold">Informação: </span>  Dados, em sentido amplo, contidos em todo e qualquer meio, suporte
                ou formato, que possam ser aproveitados para produção e para transmissão de
                conhecimento; </p>

            <p className="mb-2"><span className="font-semibold">Tratamento de dados: </span>  Toda operação realizada com dados pessoais, como as que
                se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução,
                transmissão, distribuição, processamento, arquivamento, armazenamento,
                eliminação, avaliação ou controle da informação, modificação, comunicação,
                transferência, difusão ou extração;</p>

            <p className="mb-2"><span className="font-semibold">Compartilhamento de Dados: </span>  Transferência de dados pessoais realizada entre a GT2A Sistemas Ltda Sistemas , Clientes GT2A Sistemas Ltda Sistemas e parceiros/terceiros;</p>

            <p className="mb-2"><span className="font-semibold">Bases legais: </span>  Fundamentação legal prevista na LGPD que torna legítimo o
                tratamento de dados pessoai</p>

            <p className="mb-2"><span className="font-semibold">Consentimento: </span>  Autorização expressa, inequívoca e com finalidade específica, dada
                pelo Titular de dados pessoais para que seus dados pessoais possam ser tratados
                pela GT2A Sistemas Ltda Sistemas;</p>

            <p className="mb-2"><span className="font-semibold">Teste de Proporcionalidade (LIA - Legitimate Interests Assessment): </span>     Procedimento pelo qual é realizada a análise para aplicação do Legítimo Interesse
                como Base de Tratamento; </p>

            <p className="mb-2"><span className="font-semibold">Legítimo Interesse: </span> Base de Tratamento, ou seja, permissão da LGPD, para a
                realização do tratamento de dados com fundamento no interesse do Controlador, de
                Terceiros ou do Titular de Dados;</p>

            <p className="mb-2"><span className="font-semibold">Privacidade e Proteção de Dados: </span> Conceito que trata do ato da utilização de dados pessoais e dados pessoais sensíveis sem causar discriminação ou danos de
                qualquer espécie ao seu Titular. Estará sempre relacionado à existência, criação e
                implementação de leis, procedimentos, normas internas e políticas que visam a
                garantia dos direitos dos Titulares de dados na forma da LGPD;</p>

            <p className="mb-2"><span className="font-semibold">Stakeholders: </span> São todos os públicos relevantes com interesses pertinentes à GT2A
                Sistemas Ltda Sistemas, ou ainda, indivíduos, comunidade ou entidades que
                assumam algum tipo de risco, direto ou indireto, em face da empresa;</p>

            <p className="mb-2"><span className="font-semibold">Terceiros: </span> Pessoa natural ou jurídica que presta serviços ou possui contrato de
                parceria com a GT2A Sistemas Ltda Sistemas, em suas dependências ou
                remotamente, e no exercício de suas atividades possam vir a ter acesso às
                informações referentes aos Titulares de dados que sejam tratadas pela GT2A
                Sistemas Ltda Sistemas ou pelos Clientes GT2A Sistemas Ltda Sistemas;</p>

            <p className="mb-2"><span className="font-semibold">Política: </span> Documento que contém orientações internas e descrição dos processos de
                conformidade da empresa com legislações e normas externas e internas;</p>

            <p className="mb-2"><span className="font-semibold">Inventário de Dados: </span> Documento onde são mapeadas e descritas todas as
                atividades de Tratamento de Dados. Neste documento também são relacionados os
                setores e sistemas internos da empresa, bem como determinadas as Bases de
                Tratamento que darão suporte à confecção de todos os processos e políticas
                internas da empresa;</p>

            <p className="mb-2"><span className="font-semibold">Canal de Comunicação: </span> Local/sistema/formulário que contém orientações sobre
                proteção de dados e adequação do Controlador à LGPD, bem como onde o Titular
                de Dados pode demandar seus direitos previstos nos artigos 18 e seguintes da
                LGPD;</p>

            <p className="mb-2"><span className="font-semibold">Parecer Fundamentado: </span> Documento com fundamentos jurídicos e de fato, bem
                como relatório, desenvolvimento e conclusão, construído pelo Encarregado. Este
                documento serve de base para respostas fundamentadas ao Titular de Dados
                quando este demanda os direitos previstos no artigo 18 e seguintes da LGPD;</p>

            <p className="mb-2"><span className="font-semibold">Chamado: </span> Demanda procedimentalizada onde o Titular de Dados requer acesso
                aos direitos previstos nos artigos 18 seguintes da LGPD, e o Controlador, por meio
                do Encarregado, fornece respostas e orientações ao Titular de Dados;</p>

            <p className="mb-2"><span className="font-semibold">Incidente de Segurança da Informação: </span> Evento com potencial de impactar
                negativamente o tratamento de dados e as regras de segurança da informação,
                prejudicando, assim, a integridade, a confidencialidade e/ou a autenticidade da
                informação, quando manipulada pelo Controlador, com potencial risco de causar
                danos ao Controlador, Operador, Clientes ou Terceiros;</p>

            <p className="mb-2"><span className="font-semibold">Política de Resposta à Incidentes: </span> Documento que contém as estratégias de
                resposta eficiente para controle de Incidentes de Segurança da Informação e
                processos para mitigação dos riscos e dos potenciais danos relacionados.</p>

            <p className="mb-2">III. NOME E CONTATOS DO CONTROLADOR, DO OPERADOR E DO ENCARREGADO</p>

            <p className="mb-2 font-semibold">1. CONTROLADOR</p>

            <p className="mb-2">A GT2A Sistemas Ltda Sistemas será Controladora, para fins da LGPD, quando os
                dados pessoais tratados forem coletados diretamente pela mesma. Neste caso,
                seguem as informações da Controladora:</p>

            <p className="mb-2"><span className="font-semibold">Empresa: </span> GT2A Sistemas Ltda</p>

            <p className="mb-2"><span className="font-semibold">CNPJ: </span> 16.509.447/0001-20</p>

            <p className="mb-2"><span className="font-semibold">Endereço: </span> RUA PRINCESA ISABEL, 681 - ANDAR 1 SALA 101 GARBIA COWORKING - CANOAS</p>

            <p className="mb-2"><span className="font-semibold">E-mail para contato: </span> gt2a@gt2a.com.br</p>

            <p className="mb-2 font-semibold">2. OPERADOR</p>

            <p className="mb-2">A GT2A Sistemas Ltda possui alguns parceiros que atuarão como Operadores de
                Dados de maneira continuada, para fins da LGPD, sempre com o fim de
                potencializar a qualidade dos seus serviços e a gestão de dados, os quais estão
                listados ao final deste documento, no ANEXO 2.</p>

            <p className="mb-2 font-semibold">3. ENCARREGADO</p>

            <p className="mb-2">A pessoa que atua como Encarregado, nos termos da LGPD, no âmbito geral desta
                Política de Privacidade e Proteção de Dados é:</p>

            <p className="mb-2 font-semibold">MULLER KIRCHNER E SOUZA ADVOCACIA EMPRESARIAL</p>

            <p className="mb-2"><span className="font-semibold">CNPJ: </span> 04.690.321/0001-16</p>

            <p className="mb-2"><span className="font-semibold">Endereço: </span> Alameda Aristiliano Ramos, nº 1091, 1º Andar, Bairro Jardim América,
                Município de Rio do Sul - SC, CEP. 89.160-240.</p>

            <p className="mb-2"><span className="font-semibold">E-mail para contato: </span> lgpd@mks.adv.br</p>

            <p className="mb-2"><span className="font-semibold">Observação: </span> O Titular que pretenda acionar a GT2A Sistemas Ltda para demandar
                algum dos direitos previstos na LGPD, deverá realizar contato por meio dos canais
                de comunicação acima dispostos relativos ao Encarregado ou através do Canal de
                Comunicação com o Titular contido na página virtual da GT2A Sistemas Ltdas.</p>

            <p className="mb-2">IV. COMO A GT2A Sistemas Ltda SISTEMAS COLETA DADOS, COMO SÃO TRATADOS E QUAIS BASES LEGAIS UTILIZADAS</p>

            <p className="mb-2">A GT2A Sistemas Ltda realiza o tratamento de dados pessoais:</p>

            <p className="mb-2">a) De Titulares sócios, colaboradores, representantes ou prepostos de pessoas
                jurídicas para fins de prospecção, sempre por meio da coleta de consentimento.
                Nesse caso a base legal para tratamento é o inciso I, do artigo 7º, da LGPD, de
                modo que o Titular pode exercer seu direito de revogação do consentimento a
                qualquer tempo;</p>

            <p className="mb-2">Dados coletados/tratados: nome, profissão/cargo que exerce na empresa, telefone
                (fixo ou celular – Whatsapp), sexo, organização em que trabalha, endereço de
                e-mail, endereço da empresa em que a pessoa trabalha, e qualquer outro dado
                pessoal que possa ser coletado para fins de prospecção.</p>

            <p className="mb-2">b) De Titulares sócios, colaboradores, representantes ou prepostos de pessoas
                jurídicas que são parceiras ou prestam serviços para a GT2A Sistemas Ltda. Nesse
                caso a base legal para tratamento é o inciso V, do artigo 7º, da LGPD;</p>

            <p className="mb-2">Dados coletados/tratados: nome, estado civil, CPF, RG, profissão, telefone,
                endereço, sexo, organização em que trabalha, cargo que exerce na empresa,
                endereço de e-mail, telefone (fixo ou celular – Whatsapp), e qualquer outro dado
                pessoal que possa ser coletado para fins de execução do contrato;</p>

            <p className="mb-2">c) De sócios, administradores, colaboradores, representantes ou prepostos para
                fins de execução de contratos de prestação de serviços pactuado com Clientes
                GT2A Sistemas Ltda. Nesse caso a base legal para tratamento é o inciso V, do
                artigo 7º, da LGPD; Dados coletados/tratados: nome, estado civil, CPF, RG, profissão, telefone,
                endereço, sexo, organização em que trabalha, cargo que exerce na empresa,
                endereço de e-mail, telefone (fixo ou celular – Whatsapp), e qualquer outro dado
                pessoal que possa ser coletado para fins de execução do contrato;</p>

            <p className="mb-2">d) De Titulares que realizam cadastros em páginas virtuais da GT2A Sistemas
                Ltda com o fim de receber informações, feeds de notícias ou materiais promocionais
                e informativos, a qual é realizada com base no legítimo interesse da Controladora,
                considerando o entendimento de que a realização do cadastro por parte dos
                Titulares representa o surgimento de uma obrigação a ser cumprida pela GT2A
                Sistemas Ltda, logo o legítimo interesse de cumprir a obrigação assumida perante o
                Titular mostra direta relação com a permissão legal em apreço. Nesse caso a base
                legal para tratamento é o inciso IX, do artigo 7º, da LGPD;</p>

            <p className="mb-2">Dados coletados/tratados: nome do usuário, telefone, cidade, estado, organização
                que trabalha, cargo que ocupa, endereço de e-mail corporativo..</p>

            <p className="mb-2">e) De sócios, administradores, colaboradores, representantes ou prepostos da
                GT2A Sistemas Ltda, com o fim de execução do contrato de trabalho. Nesse caso a
                base legal para tratamento é o inciso V, do artigo 7º, e inciso II, alínea d, do artigo
                11, ambos da LGPD;</p>

            <p className="mb-2">Dados coletados/tratados: nome, estado civil, profissão (cargo), dados dos
                documentos pessoais (CPF, RG, nomes dos pais, etc.), dados da Carteira de
                Trabalho, telefone, endereço, sexo, endereço de e-mail, dados bancários, nome e
                CPF de dependentes, cursos e especializações, informações de saúde, foto, ponto,
                endereço IP do seu dispositivo, e qualquer outro dado pessoal que possa ser
                coletado para fins de execução do contrato de trabalho;</p>

            <p className="mb-2"><span className="font-semibold">Obs.</span> Eventualmente para a execução de Políticas Internas de Gestão de Pessoas
                poderão ser coletados outros dados não estritamente necessários à execução do
                contrato de trabalho. Nestes casos, sempre será feita a coleta do consentimento do
                colaborador, nos termos do artigo 7º, inciso I, e do artigo 11, inciso I, da LGPD,
                conforme o caso, bem como será demonstrada a necessidade e especificidade da
                coleta de dados para fins do projeto.</p>

            <p className="mb-2">f) De Titulares que enviam seus currículos à GT2A Sistemas Ltda com o fim de
                arquivamento para futuras ou imediatas contratações, o que é realizado com base
                no legítimo interesse advindo da relação estabelecida entre o candidato/Titular e a
                Controladora. Nesse caso a base legal para tratamento é o inciso IX, do artigo 7º, da
                LGPD;</p>

            <p className="mb-2">Dados coletados/tratados: nome do Titular, telefone, endereço residencial e de
                e-mail, organização em que trabalha ou trabalhou, cargos que ocupou ou ocupa,
                contatos telefônicos de terceiros pertinentes ao objetivo, pretensão de vaga, datas
                em que trabalhou em determinadas empresas, pretensão salarial, competências,
                cursos realizados, graduação, eventuais intercâmbios, especializações, e demais
                informações que o candidato Titular decidir importantes para demonstração de suas
                competências.</p>

            <p className="mb-2">g) De Titulares de Dados que fornecerem seus Dados Pessoais para realização
                de demandas no Canal de Comunicação, com fins de exercício de seus direitos
                previstos no artigo 18 da LGPD, situação na qual se verifica o Legítimo Interesse da
                Controladora de coleta de dados para cumprimento da obrigação legal de
                responder o Titular de Dados. Nesse caso a base legal para tratamento é o inciso IX,
                do artigo 7º, da LGPD;</p>

            <p className="mb-2">Dados coletados/tratados: Nome completo, e-mail, telefone, perfil de Titular, bem
                como RG e/ou CPF, data de nascimento e nomes dos pais, extraídos dos
                documentos de identificação eventualmente utilizados para validação da identidade
                do Solicitante.</p>

            <p className="mb-2">h) Todos os dados acima poderão, eventualmente, ser tratados para
                cumprimento de obrigação legal ou regulatória ou para garantia de direitos em
                processo judicial, administrativo ou arbitral, nos termos dos incisos II e VI, do artigo
                7º, da LGPD.</p>

            <p className="mb-2"><span className="font-semibold">Observação¹:</span> A GT2A Sistemas Ltda possui Acordo para Proteção de Dados que é
                pactuado com todos os Clientes GT2A Sistemas Ltda e parceiros, o qual visa
                estabelecer os procedimentos de transferência de dados e as responsabilidades
                pertinentes a cada uma das partes.</p>

            <p className="mb-2"><span className="font-semibold">Observação²:</span> Em alguns casos os Clientes GT2A Sistemas Ltda ou parceiros
                podem realizar a coleta de dados por meio da tomada de consentimento dos
                Titulares, e nesses casos a GT2A Sistemas Ltda possui procedimento específico
                para exclusão dos dados caso o consentimento seja revogado, garantindo assim os
                direitos dos Titulares.</p>

            <p className="mb-2"><span className="font-semibold">Observação³:</span> Em alguns casos os dados coletados podem ser compartilhados com
                terceiros contratados, onde são pactuados termos de confidencialidade e Acordos
                para Proteção de Dados, com o fim de garantir a segurança dos compartilhamentos.</p>

            <p className="mb-2"> V. COMO A GT2A Sistemas Ltda ARMAZENA E TRATA DADOS PESSOAIS
                Os dados pessoais tratados pela GT2A Sistemas Ltda são armazenados: (i) Servidor
                físico interno: Armazena arquivos com dados pessoais de todos os clientes, terceiros
                e parceiros, o qual conta com backup em nuvem controlada Microsoft; (ii) Arquivo
                físico interno: Devidamente catalogados e protegidos por processos de segurança
                que envolvem limitações de acesso; (iii) E-mails: todos armazenados no servidor
                Hostgator.
                Os dados pessoais eventualmente armazenados pela GT2A Sistemas Ltda,
                permanecem retidos por um prazo, que poderá variar de acordo com a finalidade do
                tratamento dos dados pessoais e a base legal utilizada.</p>

            <p className="mb-2"> Decorridos os prazos contidos na Tabela presente no ANEXO 1, ao final deste
                documento, os mesmos são completamente excluídos da nossa base de dados.
                Os dados pessoais não serão excluídos caso possam ser anonimizados com base
                nas normas que tratam do tema, especialmente quando for necessário para o
                cumprimento de obrigação legal ou regulatória pela GT2A Sistemas Ltda.</p>

            <p className="mb-2">VI. COMO A GT2A Sistemas Ltda COMPARTILHA DADOS PESSOAIS
                Os dados pessoais dos Titulares eventualmente poderão ser compartilhados com
                terceiros através da utilização de cookies e sistemas de terceira parte. Para maiores
                informações, você pode acessar a nossa Política de Cookies</p>

            <p className="mb-2 underline">Também são compartilhados dados com os Operadores descritos no ANEXO 2.</p>

            <p className="mb-2">VII. DURAÇÃO DO ARMAZENAMENTO</p>

            <p className="mb-2">Os dados pessoais coletados pela GT2A Sistemas Ltda, de maneira geral, são
                armazenados enquanto estiverem vigentes os motivos pelos quais foram coletados.</p>

            <p className="mb-2 underline">Você pode consultar os prazos específicos na Tabela contida ao final deste
                documento.</p>

            <p className="mb-2">VIII. DIREITOS ESPECÍFICOS DOS USUÁRIOS</p>

            <p className="mb-2">O Titular de Dados conta com direitos específicos previstos no artigo 18 da LGPD.</p>

            <p className="mb-2">No site e Plataforma da GT2A Sistemas Ltda você encontrará o Canal de
                Comunicação, onde, por meio do preenchimento de um formulário, você poderá
                demandar todos os direitos contidos na LGPD</p>

            <p className="mb-2">Neste formulário, você indicará um endereço de e-mail, onde serão feitas todas as
                comunicações formais acerca da sua solicitação</p>

            <p className="mb-2">Assim, você pode nos acionar para as seguintes demandas relacionadas aos seus
                dados pessoais:</p>

            <p className="mb-2 font-bold">1. Direito à informação</p>

            <p className="mb-2">Você pode solicitar à GT2A Sistemas Ltda se os seus dados são tratados ou não,
                bem como informações sobre o Tratamento de Dados. Entre elas:</p>

            <p className="mb-2">a. porque os Dados Pessoais são tratados e qual a Base de Tratamento
                utilizada;</p>

            <p className="mb-2">b. em quais categorias estão classificados os seus Dados Pessoais;</p>

            <p className="mb-2">c. por quanto tempo seus Dados Pessoais serão armazenados.</p>

            <p className="mb-2">O prazo de resposta para esta demanda será de 24 (vinte e quatro) horas, e esta
                será realizada no mesmo e-mail indicado no formulário do Canal de Comunicação.</p>

            <p className="mb-2 font-bold">2. Direito à retificação</p>

            <p className="mb-2">Você tem o direito de requerer junto à GT2A Sistemas Ltda a complementação, a
                correção ou a atualização de seus Dados Pessoais, caso os dados tratados sejam
                equivocados ou estejam incompletos</p>

            <p className="mb-2">A GT2A Sistemas Ltda terá o prazo de 15 (quinze) dias para realizar a análise do
                seu pedido de retificação, podendo deferir ou indeferir este.</p>

            <p className="mb-2">Em caso de deferimento, você receberá em seu e-mail a resposta positiva,
                informando e comprovando a realização desta.</p>

            <p className="mb-2">Caso indeferido o pedido, a GT2A Sistemas Ltda responderá com a apresentação
                dos motivos de indeferimento.</p>

            <p className="mb-2">Você poderá apresentar novos documentos, caso os tenha, mesmo após o
                indeferimento, o que irá gerar nova análise e consequente resposta fundamentada.</p>

            <p className="mb-2 font-bold">3. Oposição à base de tratamento escolhida pelo Controlador</p>

            <p className="mb-2">Você tem o direito de se opor, a qualquer momento, à Base de Tratamento de seus
                dados pessoais, definida pela GT2A Sistemas Ltda.</p>

            <p className="mb-2">A oposição será analisada e respondida no prazo de 15 (quinze) dias, mediante a
                apresentação de Parecer Fundamentado pela Controladora.</p>

            <p className="mb-2">O Parecer Fundamentado poderá ser positivo, quando for acolhida a Base de
                Tratamento indicada por você como mais adequada ao caso, ou negativo, quando o
                seu requerimento não for acolhido, onde será mantida a Base de Tratamento
                definida pela Controladora.</p>

            <p className="mb-2 font-bold">4. Direito de anonimização, bloqueio ou eliminação de dados</p>

            <p className="mb-2">Você poderá solicitar à GT2A Sistemas Ltda a anonimização, o bloqueio ou a
                eliminação de todos os dados que eventualmente estejam sendo tratados de forma
                desnecessária, excessiva ou em desconformidade com a LGPD.</p>

            <p className="mb-2">Neste caso, a GT2A Sistemas Ltda terá o prazo de 15 (quinze) dias para apresentar
                resposta, visto que o objeto da análise demanda resposta fundamentada.</p>

            <p className="mb-2">A obrigação de eliminação de dados pessoais, acima prevista, será realizada
                quando:</p>

            <p className="mb-2">a) os Dados Pessoais não forem mais necessários aos fins para os quais foram
                coletados e tratados;</p>

            <p className="mb-2">b) ocorrer a revogação do Consentimento por parte do Titular;</p>

            <p className="mb-2">c) o Titular dos Dados se opor ao Tratamento de Dados e não existirem motivos
                fundamentados para que a GT2A Sistemas Ltda continue realizando o
                tratamento;</p>

            <p className="mb-2">d) os Dados Pessoais forem coletados de forma ilegal;</p>

            <p className="mb-2">e) existir ordem judicial ou dos órgãos fiscalizadores para exclusão.</p>

            <p className="mb-2">Caso a Controladora se posicione pela negativa do seu pedido, esta responderá a
                demanda apresentando Parecer Fundamentado que justifique os motivos do
                indeferimento da anonimização, do bloqueio ou da eliminação de dados.</p>

            <p className="mb-2">Ocorrendo o deferimento da solicitação, a GT2A Sistemas Ltda realizará a
                anonimização, o bloqueio ou a eliminação de dados, e informará o Titular de Dados
                com comprovação da realização do ato.</p>

            <p className="mb-2 font-bold">5. Informação de Compartilhamento</p>

            <p className="mb-2">Você poderá solicitar que a GT2A Sistemas Ltda diga se realiza ou não o
                compartilhamento dos seus Dados Pessoais e com quem o compartilhamento é
                realizado.</p>

            <p className="mb-2">A GT2A Sistemas Ltda realizará a resposta do seu pedido no prazo de 72 (setenta e
                duas) horas.</p>

            <p className="mb-2">Caso exista compartilhamento, você receberá a resposta indicando a lista de
                Terceiros com os quais é feito o Compartilhamento de Dados, a qual Indicará o
                nome empresarial, CNPJ e telefone de contato de cada Operador, bem como os
                fundamentos para a realização do compartilhamento.</p>

            <p className="mb-2"><span className="font-bold">Observação¹:</span> A análise e a resposta recebida por você será realizada com base na
                verificação do perfil indicado no momento do requerimento no Canal de
                Comunicação da GT2A Sistemas Ltda, logo, a indicação incorreta poderá gerar
                respostas não adequadas ao seu caso. Existindo dúvidas sobre qual é o seu perfil,
                você pode incluir esta informação na descrição da sua solicitação, de modo a
                permitir que o Encarregado realize a análise adequada da sua Solicitação.</p>

            <p className="mb-2 font-bold">6. Informações sobre Consentimento ou sua Revogação</p>

            <p className="mb-2">É possível obter informações sobre a realização de Tratamento de Dados com a
                Base de Tratamento do Consentimento, onde você poderá descobrir se existe algum
                tratamento realizado com esta permissão legal, bem como quais efeitos podem
                gerar a negativa ou revogação do Consentimento</p>

            <p className="mb-2">O prazo para resposta neste caso será 15 (quinze) dias e será apresentada mediante
                o envio de Parecer Fundamentado, o qual conterá</p>

            <p className="mb-2">a. considerações sobre o procedimento da coleta do Consentimento e a
                finalidade específica para o Tratamento dos Dados;</p>

            <p className="mb-2">b. data em que foi fornecido o Consentimento pelo Titular de Dados e o meio
                utilizado para a coleta;</p>

            <p className="mb-2">c. efeitos positivos e negativos que podem surgir da concessão do
                Consentimento e efeitos negativos ou positivos da revogação do
                Consentimento;</p>

            <p className="mb-2">d. em caso de solicitação de revogação do Consentimento, indicação do prazo
                para que todas as medidas necessárias sejam tomadas para a efetivação da
                revogação.
            </p>

            <p className="mb-2 font-bold">7. Direito de portabilidade dos dados
            </p>

            <p className="mb-2">Os dados pessoais coletados pela GT2A Sistemas Ltda poderão ser portados para
                outro fornecedor de serviços ou produtos à escolha do Titular de Dados, sempre
                mediante requisição expressa.
            </p>

            <p className="mb-2">A GT2A Sistemas Ltda deverá responder você no prazo de 72 (setenta e duas)
                horas com informações sobre a possibilidade de realizar a portabilidade e indicação
                de prazo razoável para iniciar e finalizar o procedimento de portabilidade.
            </p>

            <p className="mb-2">O prazo razoável acima indicado levará em consideração:
            </p>

            <p className="mb-2">a. volume de Dados Pessoais a serem portabilizados;
            </p>

            <p className="mb-2">b. viabilidade técnica da portabilidade;
            </p>

            <p className="mb-2">c. quantidade de fornecedores de serviços ou produtos (receptores) para os
                quais serão portabilizados os Dados Pessoais;
            </p>

            <p className="mb-2">d. capacidade de recepção dos Dados Pessoais pelos receptores.
            </p>

            <p className="mb-2">Os dados serão sempre transmitidos em um formato estruturado, comumente
                usado e legível por máquina.
            </p>

            <p className="mb-2">É importante destacar que esta portabilidade estará sempre condicionada à
                regulamentação ANPD e deverá respeitar segredos comerciais e industriais
                eventualmente vinculados aos dados.
            </p>

            <p className="mb-2 font-bold">8. Revisão de decisões automatizadas
            </p>

            <p className="mb-2">A revisão de decisões automatizadas é ato de extrema complexidade, que merece
                atenção especial do Controlador, do Operador e do Encarregado, que dependerá de
                análise detida para sua validação e eventual correção, quando apurado necessário.
            </p>

            <p className="mb-2">Assim, caso você demande a revisão de alguma decisão automatizada, a GT2A
                Sistemas Ltda terá o prazo de 15 (quinze) dias, contado da data do recebimento da
                solicitação, para definir o prazo suficiente para que seja realizado Parecer
                Fundamentado de resposta ao Titular de Dados.
            </p>

            <p className="mb-2">Logo, a sua primeira resposta será a informação do prazo necessário para a
                construção do Parecer Fundamentado.
            </p>

            <p className="mb-2">Apresentado o Parecer Fundamentado pela Controladora, o chamado ficará aberto
                pelo prazo de 30 (trinta) dias para que você possa apresentar suas razões de
                concordância ou discordância dos fundamentos apresentados pela GT2A Sistemas
                Ltda.
            </p>

            <p className="mb-2">Em caso de concordância, o chamado será finalizado
            </p>

            <p className="mb-2">Em caso de discordância, a GT2A Sistemas Ltda realizará análise e resposta final,
                onde poderá (i) reavaliar sua decisão e modificar o Parecer Fundamentado ou (ii)
                manter seu posicionamento inicial.
            </p>

            <p className="mb-2 font-bold">De qualquer forma, esta resposta final será a última atividade do chamado, o qual
                será encerrado na sequência
            </p>

            <p className="mb-2">Você possui, ainda, o direito de efetuar reclamações diretamente à ANPD ou a
                qualquer outro órgão fiscalizador, e nestes casos a GT2A Sistemas Ltda responderá
                diretamente ao órgão fiscalizador com o fim de solucionar a sua demanda.
            </p>

            <p className="mb-2">IX. COOKIES
            </p>

            <p className="mb-2">Os Cookies são pequenos arquivos eventualmente utilizados pela GT2A Sistemas
                que visam proporcionar uma melhor experiência aos usuários das páginas virtuais.
                Para saber mais acesse a nossa Política de Cookies
            </p>

            <p className="mb-2">X. SEGURANÇA DA INFORMAÇÃO
            </p>

            <p className="mb-2">A GT2A Sistemas Ltda desenvolveu métodos para alcançar as melhores práticas e
                recomendações do mercado relacionadas à segurança da informação
            </p>

            <p className="mb-2">As diretrizes que seguem são parte essencial da forma como a GT2A Sistemas Ltda
                realiza proteção de dados:
            </p>

            <p className="mb-2">a) A Segurança da Informação faz parte da estratégia e do desenvolvimento
                dos procedimentos da GT2A Sistemas Ltda, se mostrando como parte essencial dos
                trabalhos realizados e das relações estabelecidas com clientes, parceiros e terceiros;
            </p>

            <p className="mb-2">b) A prevenção como método de eliminação ou minoração de danos em
                incidentes de Segurança da Informação;
            </p>

            <p className="mb-2">c) O estabelecimento de concretas medidas disciplinares e regras com clientes,
                parceiros e colaboradores, com o fim de trazer segurança ao tratamento de dados;
            </p>

            <p className="mb-2">d) A constante atualização dos conhecimentos e procedimentos na área de
                proteção de dados da equipe, notadamente objetivando o fornecimento de produtos
                e serviços que tenham mínimo potencial de incidentes;
            </p>

            <p className="mb-2">e) O desenvolvimento continuado de ferramentas melhores e mais vinculadas à
                Segurança da Informação.
            </p>

            <p className="mb-2">XI. COOPERAÇÃO COM AUTORIDADES REGULADORAS
            </p>

            <p className="mb-2">Eventualmente a GT2A Sistemas Ltda precisará compartilhar os dados pessoais de
                seus clientes , colaboradores ou parceiros, em razão de cumprimento de lei, por
                motivo de decisão judicial ou por ordem de órgão fiscalizador.
            </p>

            <p className="mb-2">Neste momento, as informações serão reveladas unicamente nos estritos termos e
                nos limites determinados pela demandante, valendo destacar que os Titulares,
                conforme possível, serão avisados sobre a divulgação, para que, caso sintam
                necessário, possam providenciar as medidas que entenderem apropriadas.
            </p>

            <p className="mb-2">XII. ALTERAÇÕES
            </p>

            <p className="mb-2">Esta Política poderá ser alterada a qualquer tempo, seja por força de lei ou por
                necessidade de adequação da mesma às melhores práticas para proteção de dados.
                As alterações serão publicadas pela GT2A Sistemas Ltda em seus meios de
                comunicação oficiais.
            </p>

            <p className="mb-2">XIII. RESPONSABILIDADES
            </p>

            <p className="mb-2 font-bold">1. Administradores, colaboradores e terceiros contratados
            </p>

            <p className="mb-2 font-bold">a) Observar e zelar pelo cumprimento da presente Política e, quando assim se
                fizer necessário, acionar o Encarregado em Privacidade e Proteção de Dados (DPO)
                para consulta sobre situações que envolvam conflito com esta Política ou mediante
                a ocorrência de situações nela descritas.
            </p>

            <p className="mb-2">a) Observar e zelar pelo cumprimento da presente Política e, quando assim se
                fizer necessário, acionar o Encarregado em Privacidade e Proteção de Dados (DPO)
                para consulta sobre situações que envolvam conflito com esta Política ou mediante
                a ocorrência de situações nela descritas.
            </p>

            <p className="mb-2 font-bold">2. Diretoria, equipe de gestão de risco e encarregado
            </p>

            <p className="mb-2">a) Manter atualizada esta Política com base nas alterações regulatórias/legais
                pertinentes, garantindo que as regras ora estabelecidas sejam cumpridas;
            </p>

            <p className="mb-2">b) Esclarecer dúvidas relativas a esta Política e à sua aplicação;
            </p>

            <p className="mb-2">c) Receber reclamações e solicitações dos Titulares dos dados, prestar
                esclarecimentos e adotar ou determinar as providências necessárias;
            </p>

            <p className="mb-2">d) Receber e processar comunicações da Autoridade Nacional de Proteção de
                Dados (ANPD), ou de outros órgãos fiscalizadores, e adotar providências;
            </p>

            <p className="mb-2">e) Realizar treinamento e orientação dos colaboradores e dos terceiros, quando
                assim contratado, acerca de todos os procedimentos internos para proteção de
                dados pessoais.
            </p>

            <p className="mb-2">XIV. PENALIDADES
            </p>

            <p className="mb-2">As penalidades institucionais possíveis para os funcionários que violarem o disposto
                nesta Política incluem advertência formal, suspensão e até demissão.
                Os demais Stakeholders serão notificados extrajudicialmente para corrigir a
                desconformidade verificada ou para o rompimento dos vínculos existentes com a
                GT2A Sistemas Ltda, sempre mediante avaliação da gravidade e potencial danoso
                do descumprimento das normas previstas neste documento, sem prejuízo da
                aplicação das penalidade contratuais existentes em outros documentos estipulados
                entre as partes, e à apuração de perdas e danos eventualmente sofridas pela GT2A
                Sistemas Ltda.
            </p>

            <p className="mb-2">XV. VIGÊNCIA
            </p>

            <p className="mb-2">Esta Política entra em vigor na data da sua aprovação pela Diretoria da GT2A
                Sistemas Ltda, revogando quaisquer outros documentos em contrário, podendo ser
                modificada a qualquer tempo, mediante publicidade pelos meios de comunicação
                oficiais
            </p>

            <p className="mb-2">Rio do Sul (SC), 30 de agosto de 2023
            </p>

            <p className="mb-2 text-center mt-3">ANEXO 1 - TABELA DE PRAZOS DE GUARDA
            </p>

            <table className="table text-center table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Documento</th>
                        <th scope="col">Tempo de Guarda
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Dados Pessoais dos Colaboradores coletados para
                            fins de realização de atividade extraordinária, não
                            vinculada diretamente à relação de emprego.</td>
                        <td>30 dias: Após o
                            encerramento da atividade.
                            Prazo definido internamente
                            pela empresa.</td>
                    </tr>
                    <tr>
                        <td>Aviso prévio</td>
                        <td>2 anos: Prazo para ação
                            trabalhista após o
                            encerramento da
                            contratação.</td>
                    </tr>
                    <tr>
                        <td>Pedido de demissão
                        </td>
                        <td>2 anos: Prazo para ação
                            trabalhista após o
                            encerramento da
                            contratação.
                        </td>
                    </tr>
                    <tr>
                        <td>TRCT (Termo de Rescisão do Contrato de Trabalho)
                        </td>
                        <td>2 anos: Art. 7º, XXIX, CF.
                            Prazo para ação trabalhista
                            após o encerramento da
                            contratação.

                        </td>
                    </tr>
                    <tr>
                        <td>Dados Pessoais coletados de Titulares de Dados por
                            meio do Canal de Comunicação para atendimento de
                            demandas do artigo 18 da LGPD.

                        </td>
                        <td>5 anos: Para fins de
                            eventuais demandas
                            judiciais ou reclamações
                            administrativas junto à
                            ANPD.

                        </td>
                    </tr>
                    <tr>
                        <td>Contratos e documentos relacionados ao contrato
                            firmado (Contrato, aditivos, comprovantes de
                            pagamento, comprovantes de entrega, notas fiscais,
                            recibos, faturas, correio eletrônico ou outros registros
                            de comunicações entre as partes, etc)

                        </td>
                        <td>5 anos: Art. 206, §5º, do
                            Código Civil, após o término
                            da relação, para fins de
                            eventuais demandas
                            judiciais relativas à
                            contratação
                        </td>
                    </tr>
                    <tr>
                        <td>CAGED (Cadastro Geral de Empregados e
                            Desempregados)

                        </td>
                        <td>5 anos: Contar da data do
                            envio do arquivo ao
                            Ministério do Trabalho e
                            Emprego (MTE). Art. 1º, § 2º,
                            Portaria MTE 235/03, que
                            prevê o prazo de 3 anos,
                            contados do término do
                            prazo de dois anos para
                            ações trabalhistas após o
                            encerramento da
                            contratação
                        </td>
                    </tr>
                    <tr>
                        <td>Acordo de compensação de horas
                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Acordo de prorrogação de horas

                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Adiantamento salarial (para efeitos trabalhistas)
                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Atestados médicos (para efeitos trabalhistas)

                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Autorização de descontos

                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>CIPA (Comissão Interna de Prevenção de Acidentes)
                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Controle de ponto
                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Mapa Anual de Acidentes do Trabalho

                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Recibos de pagamento de abono pecuniário
                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Recibos de pagamento e adiantamento salarial
                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Recibos de pagamento e gozo de férias

                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT

                        </td>
                    </tr>
                    <tr>
                        <td>Recibos de pagamentos do 13º salário

                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Salário - relação de depósitos bancários
                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Seguro Desemprego (Comunicação de Dispensa - CD
                            e Requerimento do Seguro-Desemprego - SD)

                        </td>
                        <td>5 anos: Contados a partir da
                            dispensa do colaborador.
                            Art. 7º, XXIX, CF.

                        </td>
                    </tr>
                    <tr>
                        <td>Vale-transporte (pedido, recibo de entrega, alteração
                            de endereço etc)

                        </td>
                        <td>5 anos: Art. 7º, XXIX, CF e
                            art. 11 da CLT
                        </td>
                    </tr>
                    <tr>
                        <td>Comprovante de entrega da Guia da Previdência
                            Social ao sindicato profissional

                        </td>
                        <td>5 anos: Contados da
                            entrega da Guia. Art. 45, Lei
                            8.212/91 c/c Súmula
                            Vinculante nº 8 STF

                        </td>
                    </tr>
                    <tr>
                        <td>Comprovante de pagamento ou a declaração
                            apresentada pelo contribuinte individual para fins de
                            apresentação ao INSS

                        </td>
                        <td>5 anos: Contados a partir da
                            dispensa do colaborador.
                            Art. 45, Lei 8.212/91 c/c
                            Súmula Vinculante nº 8 STF.
                        </td>
                    </tr>
                    <tr>
                        <td>Comprovante de pagamento de benefícios
                            reembolsados pelo INSS

                        </td>
                        <td>5 anos: Contados a partir da
                            dispensa do colaborador.
                            Art. 45, Lei 8.212/91 c/c
                            Súmula Vinculante nº 8 STF.
                        </td>
                    </tr>
                    <tr>
                        <td>Comprovante de pagamento ou declaração do
                            segurado

                        </td>
                        <td>5 anos: Contados a partir da
                            dispensa do colaborador.
                            Art. 45, Lei 8.212/91 c/c
                            Súmula Vinculante nº 8 STF
                        </td>
                    </tr>
                    <tr>
                        <td>Comunicação de Acidente de Trabalho (CAT)

                        </td>
                        <td>5 anos: Contados a partir da
                            dispensa do colaborador.
                            Art. 45, Lei 8.212/91 c/c
                            Súmula Vinculante nº 8 STF.
                        </td>
                    </tr>
                    <tr>
                        <td>Documentos que comprovem a isenção da
                            contribuição previdenciária
                        </td>
                        <td>5 anos: Contados a partir da
                            dispensa do colaborador.
                            Art. 45, Lei 8.212/91 c/c
                            Súmula Vinculante nº 8 STF.
                        </td>
                    </tr>
                    <tr>
                        <td>Salário-família - documentos relacionados ao
                            benefício
                        </td>
                        <td>5 anos. Regra geral do CTN.

                        </td>
                    </tr>
                    <tr>
                        <td>Salário-maternidade - documentos relacionados ao
                            benefício
                        </td>
                        <td>5 anos. Regra geral do CTN.

                        </td>
                    </tr>
                    <tr>
                        <td>Demais documentos sujeitos à fiscalização do INSS
                        </td>
                        <td>5 anos: Contados a partir da
                            dispensa do colaborador.
                        </td>
                    </tr>
                    <tr>
                        <td>RAIS (Relação Anual de Informações Sociais)

                        </td>
                        <td>5 anos: Art. 9º, Portaria MTE
                            651/07

                        </td>
                    </tr>
                    <tr>
                        <td>Guia da Previdência Social (GPS)

                        </td>
                        <td>5 anos: Art. 45, Lei
                            8.212/91 c/c Súmula
                            Vinculante nº 8 STF.
                        </td>
                    </tr>
                    <tr>
                        <td>GRCSU (Guia de Recolhimento de Contribuição
                            Sindical Urbana) - para as contribuições não
                            recolhidas não há prazo prescricional
                        </td>
                        <td>5 anos: Arts. 578/579 da
                            CLT c/c arts. 173 e 217 CTN
                        </td>
                    </tr>
                    <tr>
                        <td>PIS – Programa de Integração Social
                        </td>
                        <td>5 anos: Regra geral do CTN,
                            diante da não existência de
                            regras próprias, pois os
                            artigos 45 e 46 da Lei
                            8.212/91, que previam o
                            prazo de 10 anos, foram
                            revogados.

                        </td>
                    </tr>
                    <tr>
                        <td>Documentos fiscais (notas fiscais, guias de
                            recolhimento de impostos, declarações fiscais, etc)

                        </td>
                        <td>5 anos. Regra geral do CTN.
                            Importante: a partir do fim
                            do exercício em que foram
                            utilizados
                        </td>
                    </tr>
                    <tr>
                        <td>Salário-educação - documentos relacionados ao
                            benefício
                        </td>
                        <td>10 anos: Art. 10 do
                            Decreto-Lei 2.052/83 c/c art.
                            1º do Decreto nº 6003/2006
                        </td>
                    </tr>
                    <tr>
                        <td>Contratos e documentos societários (contratos,
                            estatutos sociais, atas de reuniões e outros
                            documentos relacionados à constituição e
                            administração da empresa)

                        </td>
                        <td>10 anos: art. 205 do Código
                            Civil, para fins de eventuais
                            demandas judiciais acerca
                            da constituição,
                            funcionamento e extinção de
                            empresas.

                        </td>
                    </tr>
                    <tr>
                        <td>Folha de Pagamento
                        </td>
                        <td>10 anos: Art. 225, I e § 5º,
                            Dec. 3048/99
                        </td>
                    </tr>
                    <tr>
                        <td>Programa de Controle Médico de Saúde Ocupacional
                            (PCMSO) – Exames médicos admissional, periódico,
                            de retorno ao trabalho, mudança de função e
                            demissional (contados após o desligamento do
                            empregado).

                        </td>
                        <td>20 anos: Após o
                            desligamento do
                            trabalhador
                        </td>
                    </tr>
                    <tr>
                        <td>Perfil Profissiográfico Previdenciário (PPP)
                        </td>
                        <td>20 anos
                        </td>
                    </tr>
                    <tr>
                        <td>Programa de Prevenção de Riscos Ambientais
                            (PPRA) – toda documentação
                        </td>
                        <td>20 anos
                        </td>
                    </tr>
                    <tr>
                        <td>Fundo de Garantia do Tempo de Serviço (FGTS) -
                            depósitos e documentos relacionados
                        </td>
                        <td>30 anos: art. 23, § 5º, Lei
                            8036/90 e Súmula 362 TST
                            para relações anteriores à
                            novembro de 2014. E 5
                            anos: inciso III do artigo 7º
                            da Constituição da
                            República mais o novo
                            entendimento do TST, para
                            relações posteriores à
                            novembro de 2014.

                        </td>
                    </tr>
                    <tr>
                        <td>Guia de Recolhimento do Fundo de Garantia do
                            Tempo de Serviço e Informações à Previdência Social
                            (GFIP)

                        </td>
                        <td>30 anos: Artigo 23, § 5º, Lei
                            8036/90

                        </td>
                    </tr>
                    <tr>
                        <td>Guia de Recolhimento Rescisório do FGTS (GRRF)

                        </td>
                        <td>
                            30 anos: Artigo 23, § 5º, Lei
                            8036/90
                        </td>
                    </tr>
                    <tr>
                        <td>Livro de Inspeção do Trabalho

                        </td>
                        <td>Indeterminado
                        </td>
                    </tr>
                    <tr>
                        <td>Livros/fichas de registro de empregados

                        </td>
                        <td>Indeterminado
                        </td>
                    </tr>
                    <tr>
                        <td>Livro "Registro de Segurança"
                        </td>
                        <td>Indeterminado
                        </td>
                    </tr>
                    <tr>
                        <td>Contrato de trabalho
                        </td>
                        <td>Indeterminado
                        </td>
                    </tr>
                    <tr>
                        <td>Comprovante de exercício da atividade remunerada
                            dos segurados e contribuintes individuais
                        </td>
                        <td>Indeterminado
                        </td>
                    </tr>
                    <tr>
                        <td>Livros de atas da CIPA (Comissão Interna de
                            Prevenção de Acidentes)

                        </td>
                        <td>Indeterminado
                        </td>
                    </tr>
                    <tr>
                        <td>Livros de atas da CIPA (Comissão Interna de
                            Prevenção de Acidentes)

                        </td>
                        <td>Indeterminado
                        </td>
                    </tr>
                </tbody>
            </table>

            <p className="mb-2 text-center mt-3">ANEXO 1 - TABELA DE PRAZOS DE GUARDA</p>

            <table className="table text-center table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Operador</th>
                        <th scope="col">Informações de Contato</th>
                    </tr>
                    <tr>
                        <td><p><strong>Empresa</strong></p>
                            <p>CNPJ:</p>
                        </td>
                        <td>
                            <p>Endereço:</p>
                            <p>E-mail para contato:</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Empresa</strong></p>
                            <p>CNPJ:</p>
                        </td>
                        <td>
                            <p>Endereço:</p>
                            <p>E-mail para contato:</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Empresa</strong></p>
                            <p>CNPJ:</p>
                        </td>
                        <td>
                            <p>Endereço:</p>
                            <p>E-mail para contato:</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Empresa</strong></p>
                            <p>CNPJ:</p>
                        </td>
                        <td>
                            <p>Endereço:</p>
                            <p>E-mail para contato:</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Empresa</strong></p>
                            <p>CNPJ:</p>
                        </td>
                        <td>
                            <p>Endereço:</p>
                            <p>E-mail para contato:</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Empresa</strong></p>
                            <p>CNPJ:</p>
                        </td>
                        <td>
                            <p>Endereço:</p>
                            <p>E-mail para contato:</p>
                        </td>
                    </tr>
                    <tr>
                        <td><p><strong>Empresa</strong></p>
                            <p>CNPJ:</p>
                        </td>
                        <td>
                            <p>Endereço:</p>
                            <p>E-mail para contato:</p>
                        </td>
                    </tr>
                </thead>
            </table>

        </div>
    )
}

export default ProtecaoDados