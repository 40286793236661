import React, { useState } from "react";
import LoginADM from "./LoginADM";
import PainelSincornizacao from "./PainelSincronizacao";

function AutenticacaoADM(props) {
    const [user, setUser] = useState(false)

    return (
        <div>
            {
                user ? <PainelSincornizacao /> : <LoginADM setUser={setUser} />
            }
        </div>
    )
}

export default AutenticacaoADM